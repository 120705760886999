
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";
import { EmployeePasswordForm } from "@/store/employee/types";

const VEmployeePassword = defineComponent({
  name: "VEmployeePassword",
  components: { CModal, CFormInputText },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);

    useHead({ title: `Atualizar senha do usuário | obmed` });

    const employee = computed(() => store.state.employee.current);

    const form = reactive<EmployeePasswordForm>({
      ds_senha: "",
      ds_senha_rep: "",
      cd_grupos: [],
      cd_estabelecimentos: [],
    });
    const show = reactive({ password: false });
    const loading = reactive({ submit: false, employee: false });

    function handleClose() {
      router.back();
    }

    async function getEmployee() {
      loading.employee = true;
      await store.dispatch("getEmployee", { _id });
      loading.employee = false;

      if (!employee.value) handleClose();

      form.cd_grupos = employee.value?.grupos?.map((item) => item.id) || [];
      form.cd_estabelecimentos = employee.value?.estabelecimentos?.map((item) => item.id) || [];
    }

    async function onSubmit() {
      loading.submit = true;
      const response = await store.dispatch("updateEmployeePassword", { _id, form });
      loading.submit = false;

      if (response?.status === 200) handleClose();
    }

    getEmployee();

    return { form, show, loading, handleClose, onSubmit };
  },
});

export default VEmployeePassword;
